<template>
    <div class="content profile-content notifications">
        <ProfileNavbar :title="$t('profile.notifications.title')"></ProfileNavbar>
       <div class="mob-menu-title">
<!--            <router-link tag="a" to="/profile"  class="mob-menu-back">-->
<!--                <img src="images/profile/arrow-back.svg">-->
<!--            </router-link>-->
           {{ $t('profile.notifications.title') }}
       </div>
        <div class="clearfix">
            <div class="bg-blue">
                <div class="col-md-12 filter">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-8"> -->
                            <div class="tabsMenu">
                                <ul class="nav nav-tabs d-flex flex-row">
                                    <li class="nav-item">
                                        <a class="nav-link notifications-title text-center" @click.prevent="setActive('new')" :class="{ active: isActive('new') }" href="#new">{{ $t("profile.notifications.new-notifications-tab-title") }} ({{newNotifications.length}})</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link notifications-title text-center" @click.prevent="setActive('viewed')" :class="{ active: isActive('viewed') }" href="#viewed">{{ $t("profile.notifications.viewed-notification-tab-title") }} ({{viewedNotifications.length}})</a>
                                    </li>
                                </ul>
                            </div>
                        <!-- </div> -->
                        <div>
                            <button class="btn btn-filter ml-3 filter-title" type="button"
                                    @click="toggleFilterForm">{{$t("profile.notifications.filter-title")}}
                            </button>
                            <div class="form-filter" :class="{'show': isFilterFormVisible}">
                                <div class="">
                                    <div class="form-check">
                                        <label class="form-check-label" for="all">
                                            <input @change="onChange($event)" class="form-check-input" type="radio" name="filter" id="all" value="1" checked>
                                            <div class="form-check-title">{{$t("profile.notifications.filter-all-title")}}</div>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" for="firstPart">
                                            <input @change="onChange($event)" class="form-check-input" type="radio" name="filter" id="firstPart" value="2">
                                            <div class="form-check-title">{{$t("profile.notifications.filter-consultations-title")}}</div>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" for="secondPart">
                                            <input @change="onChange($event)" class="form-check-input" type="radio" name="filter" id="secondPart" value="3">
                                            <div class="form-check-title">{{$t("profile.notifications.filter-testing-title")}}</div>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
<!--                                    <div class="form-check">-->
<!--                                        <label class="form-check-label" for="startSchool">-->
<!--                                            <input @change="onChange($event)" class="form-check-input" type="radio" name="filter" id="startSchool" value="3">-->
<!--                                            <div class="form-check-title">{{$t("profile.notifications.filter-surveys-title")}}</div>-->
<!--                                            <span class="checkmark"></span>-->
<!--                                        </label>-->
<!--                                    </div>-->
                                </div>
                                <div class="filter-btns mt-4">
                                    <button class="btn btn-filter-modal" @click="getFilterNotifications()">{{$t("profile.notifications.filter-btn-title")}}</button>
                                    <button class="btn btn-filter-modal" @click="clearFilter">{{$t("profile.notifications.filter-btn-clear-title")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-blue">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="{ 'active show': isActive('new') }" id="new">
                            <div class="notification-block">
                                <h3 class="new-notification-title">{{$t('profile.notifications.new-notification-title')}}</h3>
                                <div class="col-md-12 mt-5">
                                    <div v-if="newNotifications.length < 1">
                                        {{$t('profile.notifications.notifications-not-found')}}
                                    </div>
                                    <div class="row notification mt-1" v-for="notification in newNotifications">
                                        <div class="col-md-3 notification-title">
                                            <!--{{$t('profile.notifications.notification-title-1')}}-->
<!--                                            {{notification}}-->
                                        </div>
                                        <div class="col-md-7 justify-content-center notification-description" v-html="notification.message">
                                            <!--{{$t('profile.notifications.notification-message')}}-->
                                        </div>
                                        <div class="col-md-2 justify-content-center notification-data">
                                            {{notification.date}}
                                            <span class="notification-data-img">
                                                <img src="/images/profile/notification-image.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ 'active show': isActive('viewed') }" id="viewed">
                            <div class="notification-block">
                                <h3 class="new-notification-title">{{$t('profile.notifications.viewed-notifications-title')}}</h3>
                                <div class="col-md-12 mt-5">
                                    <div v-if="viewedNotifications.length < 1">
                                        {{$t('profile.notifications.notifications-not-found')}}
                                    </div>
                                    <div class="row notification mt-1" v-for="notification in viewedNotifications">
                                        <div class="col-md-3 justify-content-center notification-title">
                                            {{notification.title}}
                                            <!--{{$t('profile.notifications.notification-title-1')}}-->
                                        </div>
                                        <div class="col-md-7 justify-content-center notification-description" v-html="notification.message">
                                            <!--{{$t('profile.notifications.notification-message')}}-->
                                        </div>
                                        <div class="col-md-2 justify-content-center notification-data">
                                            {{notification.date}}
                                            <!--20.01.2021 9:30-->
                                            <span class="notification-data-img">
                                                <img src="/images/profile/notification-image.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>
<script>
    import Vue from 'vue';
    import ProfileNavbar from "@/components/ProfileNavbar";
    export default {
        name: 'Notification',
        components: {
            ProfileNavbar,
        },
        data() {
            return {
                filter: 0,
                newNotifications: [
                    // {
                    //     title: 'Перенос консультации',
                    //     message: 'Консультация была пересена на 15 января 2021г',
                    //     date: '20.01.2021 9:30'
                    // },
                    // {
                    //     title: 'Пройдите опрос',
                    //     message: 'Пройдите несложный опрос.Ваше мнение очень важно для нас! ',
                    //     date: '20.01.2021 9:30'
                    // },
                    // {
                    //     title: 'Новое тестирование',
                    //     message: 'Появилось новое тестирование, пройдите его, что бы узнать свои результаты',
                    //     date: '20.01.2021 9:30'
                    // }
                ],
                viewedNotifications: [],
                activeItem: 'new',
                isFilterFormVisible: false,
            }
        },



        methods: {
            toggleFilterForm() {
                this.isFilterFormVisible = !this.isFilterFormVisible
            },
            clearFilter() {
              this.newNotifications=[]
              this.viewedNotifications=[]
              this.$http.get(window.API_ROOT + '/api/notifications')
                  .then((res) => {
                    for (var i=0; res.body.data.length>i; i++){
                      console.log(res.body.data[i])
                      if(res.body.data[i].readed==null){
                        var dataConvert=res.body.data[i].created.substr(0,10).split('-').reverse().join('.')
                        this.newNotifications.push({'date':dataConvert,'message': res.body.data[i].data.message})
                        this.$http.get(window.API_ROOT + '/api/notifications/read/' + res.body.data[i].id)
                            .then((res) => {

                            })

                      }
                      else if(res.body.data[i].readed!=null){
                        var dataConvert=res.body.data[i].created.substr(0,10).split('-').reverse().join('.')
                        this.viewedNotifications.push({'date':dataConvert,'message': res.body.data[i].data.message})
                      }
                    }


                  }).catch((e) => {
                Vue.toastr({
                  message: 'Ошибка',
                  description: "Ошибка" ,
                  type: 'error'
                });
              });
                this.isFilterFormVisible = false
            },
            isActive (menuItem) {
                return this.activeItem === menuItem
            },
            setActive (menuItem) {
                this.activeItem = menuItem
            },
            onChange(filter) {
                this.filter = filter.target.value
            },
            getFilterNotifications() {
              this.newNotifications=[]
              this.viewedNotifications=[]
                this.$http.get(window.API_ROOT + '/api/notifications?type=' + this.filter)
                    .then((res) => {
                      for (var i=0; res.body.data.length>i; i++){
                        console.log(res.body.data[i])
                        if(res.body.data[i].readed==null){
                          var dataConvert=res.body.data[i].created.substr(0,10).split('-').reverse().join('.')
                          this.newNotifications.push({'date':dataConvert,'message': res.body.data[i].data.message}
                          )
                        }
                        else if(res.body.data[i].readed!=null){
                          var dataConvert=res.body.data[i].created.substr(0,10).split('-').reverse().join('.')
                          this.viewedNotifications.push({'date':dataConvert,'message': res.body.data[i].data.message})
                        }
                      }


                    }).catch((e) => {
                  Vue.toastr({
                    message: 'Ошибка',
                    description: "Ошибка" ,
                    type: 'error'
                  });
                });
            }
        },
        mounted() {
          this.clearFilter()


        }
    }
</script>
<style scoped>
@media(max-width: 992px){
.filter {
  padding: 42px 16px 52px 16px
}}
</style>
<style scoped>
    .notification {
        height: 69px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    }
    .notification-data-img {
        margin-left: 10px;
    }
    .notification-title {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #05458C;
    }
    .notification-description {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        color: #05458C;
    }
    .notification-data {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #05458C;
    }
    .filter {
        padding-left: 0px;
        display: flex;
        flex-direction: row;
    }
    .notifications-title {
        width: 218px;
    }
    .btn-filter {
        padding: 8px 36px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px;
        color: black;
    }
    .form-filter {
        border: 1px solid #E8E8E8;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
        padding: 20px;
        position: absolute;
        top: 50px;
        left: 20px;
        width: 414px;
        visibility: hidden;
        transition: all 0.3s linear;
        opacity: 0;
        transform: translateY(-20px);
    }

    .form-filter.show {
        z-index: 1;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .btn-filter-modal {
        padding: 11px 30px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .form-check{
        margin-top: 8px;
        padding-left: 0;
    }

    .notification-block {
        margin-top: 60px;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        /* padding: 9px 10px; */
        background: #04C2F4;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
    }

    .nav-tabs .nav-link {
        color: black;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 0px 5px 5px 0px;
    }

    .nav-tabs {
        border-bottom: none;
    }

    .col-md-8 {
        max-width: 44%;
    }
    .form-check-label {
        display: block;
        position: relative;
        padding-left: 45px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .form-check-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid #eee;
        border-radius: 50%;
    }
    .form-check-title {
        padding-top: 2px;
    }
    .form-check-label:hover input ~ .checkmark {
        background-color: #eee;
    }
    .form-check-label input:checked ~ .checkmark {
        background-color: #04C2F4;
    }
    .form-check-label input:checked ~ .form-check-title {
        color: #04C2F4;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .form-check-label input:checked ~ .checkmark:after {
        display: block;
    }
    .form-check-label .checkmark:after {
        top: 4px;
        left: 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: white;
    }
    .filter-btns .btn-filter-modal:first-child {
        background-color: #04C2F4;
        color: #fff;
    }
    .filter-btns .btn-filter-modal:last-child {
        margin-left: 8px;
    }
    .filter-btns :is(button) {
        font-size: 14px;
    }
    .tab-content {
        max-width: 1120px;
    }
    @media screen and (max-width: 991px) {
        .notification-block {
            padding-bottom: 40px;
        }
        .filter {
            /* padding-left: 0px; */
            display: flex;
            flex-direction: column;
            padding: 0 16px 52px 16px;
        }
        .notifications .btn-filter {
            margin-left: 0 !important;
            margin-top: 20px;
            font-size: 16px;
            line-height: 24px;
        }
        .notifications-title {
            width: 256px;
        }
        .notifications .nav-tabs {
            margin-top: 32px;
        }
    }
    @media screen and (max-width: 767px) {
        .notification-title {
            justify-content: center;
        }
        .notifications .tabsMenuр .nav-tabs .nav-item {
            width: 50%;
        }
        .notifications-title {
            width: 100%;
        }
        .filter {
            display: flex;
            flex-direction: column;
        }
        .filter-btns {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .filter-btns .btn-filter-modal:first-child {
            margin-bottom: 14px;
            padding: 11px 53px;
        }
        .filter-btns .btn-filter-modal:last-child {
            padding: 12px 36px;
            margin-left: 0;
        }
        .col-md-8 {
            max-width: 100%;
        }
        .form-filter {
            left: 15px;
            top: 150px;
            width: 325px;
        }
        .btn-filter-modal {
            padding: 12px 10px;
        }
        .notification-data-img {
            position: absolute;
            top: -60px;
        }
        .notification-data-img img {
            width: 75px;
            height: 50px;
        }
        .notification-data {
            position: absolute;
            margin-top: 123px;
        }
        .notification {
            margin-top: 20px !important;
            height: 239px;
            display: flex;
            padding: 16px;
            flex-direction: column;
            justify-content: space-between;
        }
        .notification-description {
            font-size: 16px;
            line-height: 19px;
            text-align: left;
        }
        .notification-data-img {
            margin-left: 0;
        }
        .notification-block {
            margin-top: 0;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 50%;
        }
        .notifications .nav-tabs .nav-item {
            width: 50%;
        }
        .notifications .nav-tabs .nav-item .notifications-titl {
            width: 100%;
        }
    }
</style>